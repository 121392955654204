import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; // Asegúrate de importar i18n
import enFlag from './assets/en-flag.jpg'; // Ruta de la bandera de España
import esFlag from './assets/es-flag.png'; // Ruta de la bandera de EE. UU.
import pixelPhoto from './assets/pixelPhoto.jpeg'; // Ruta de la foto de perfil
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';


const App = () => {
  const { t } = useTranslation();

  const GoDown = () => {
    const element = document.querySelector('.Projects');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <header className="relative py-16 px-4">
        {/* Language switcher */}
        <div className="absolute top-4 right-4 flex gap-2">
          <button 
            onClick={() => changeLanguage('es')} 
            className="w-8 h-8 rounded-full overflow-hidden border-2 border-gray-200 hover:border-blue-500 transition-all"
          >
            <img src={esFlag} alt="Español" className="w-full h-full object-cover"/>
          </button>
          <button 
            onClick={() => changeLanguage('en')} 
            className="w-8 h-8 rounded-full overflow-hidden border-2 border-gray-200 hover:border-blue-500 transition-all"
          >
            <img src={enFlag} alt="English" className="w-full h-full object-cover" />
          </button>
        </div>

        {/* Hero section with profile */}
        <div className="max-w-4xl mx-auto text-center">
          <div className="mb-8">
          <div className="w-40 h-40 mx-auto mb-6 rounded-full overflow-hidden border-4 border-white shadow-lg">
  <img 
    src={pixelPhoto} 
    alt="Profile" 
    className="w-full h-full object-cover" 
    style={{ objectPosition: "center 30%" }} 
  />
</div>

            <h1 className="text-4xl font-bold text-gray-800 mb-2">Luis Ruiz Nuñez</h1>
            <h2 className="text-xl text-blue-600 mb-4">{t('softwareEngineer')}</h2>
            <div className="flex justify-center gap-4 mb-8">
              <a 
                href="https://www.linkedin.com/in/luis-ruiz-nu%C3%B1ez-b69569185" 
                target="_blank" 
                rel="noreferrer"
                className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition-all"
              >
                <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" className="w-6 h-6" />
              </a>
              <a 
                href="https://github.com/LuiRui07" 
                target="_blank" 
                rel="noreferrer"
                className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition-all"
              >
                <img src="https://cdn-icons-png.flaticon.com/512/733/733553.png" alt="GitHub" className="w-6 h-6" />
              </a>
            </div>
            <button 
              onClick={GoDown}
              className="px-6 py-3 bg-blue-600 text-white rounded-full font-medium hover:bg-blue-700 transition-colors shadow-md hover:shadow-lg"
            >
              {t('viewMyWork')}
            </button>
          </div>
        </div>
      </header>

      {/* CV Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-4xl mx-auto">
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-8">
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('personalInfo')}</h3>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2">
                    <span className="font-medium">{t('email')}:</span>
                    <a href="mailto:luisruiznunez@gmail.com" className="text-blue-600 hover:underline">
                      luisruiznunez@gmail.com
                    </a>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="font-medium">{t('phone')}:</span>
                    <span>+34 123 456 789</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="font-medium">{t('location')}:</span>
                    <span>Málaga, España</span>
                  </li>
                </ul>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('skills')}</h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">{t('frontendSkills')}</h4>
                    <div className="flex flex-wrap gap-2">
                      {['React', 'JavaScript', 'HTML', 'CSS', 'Tailwind CSS'].map(skill => (
                        <span key={skill} className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">{t('backendSkills')}</h4>
                    <div className="flex flex-wrap gap-2">
                      {['Node.js', 'Express', 'Spring Boot'].map(skill => (
                        <span key={skill} className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">{t('databaseSkills')}</h4>
                    <div className="flex flex-wrap gap-2">
                      {['MySQL', 'PostgreSQL', 'MongoDB'].map(skill => (
                        <span key={skill} className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm">
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-8">
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('experience')}</h3>
                <div className="border-l-2 border-blue-500 pl-4">
                  <h4 className="font-medium text-gray-800">{t('companyName')}</h4>
                  <p className="text-sm text-gray-600 mb-2">Jan 2022 - Present</p>
                  <p className="text-gray-700">{t('jobDescription')}</p>
                </div>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('education')}</h3>
                <div className="border-l-2 border-blue-500 pl-4">
                  <h4 className="font-medium text-gray-800">{t('universityName')}</h4>
                  <p className="text-sm text-gray-600 mb-2">2015 - 2020</p>
                  <p className="text-gray-700">{t('degree')}</p>
                </div>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('languages')}</h3>
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="font-medium">{t('spanish')}</span>
                    <span className="text-sm text-gray-600">{t('native')}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="font-medium">{t('english')}</span>
                    <span className="text-sm text-gray-600">{t('fluent')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="Projects py-16 px-4 bg-gray-50">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">{t('projects')}</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {[
              {
                icon: <FontAwesomeIcon icon={faAndroid} className="w-6 h-6" />,
                title: t('project1'),
                description: t('project1Description'),
                link: 'https://github.com/LuiRui07/TFG'
              },
              {
                icon: <img src="react-icon.svg" alt="React" className="w-6 h-6" />,
                title: t('project2'),
                description: t('project2Description'),
                link: 'https://github.com/LuiRui07/elRastro'
              },
              {
                icon: <img src="./spring-icon.svg" alt="Spring" className="w-6 h-6" />,
                title: t('project3'),
                description: t('project3Description'),
                link: 'https://github.com/LuiRui07/SkyBank'
              },
              {
                icon: <img src="./swift.svg" alt="Swift" className="w-6 h-6" />,
                title: t('project4'),
                description: t('project4Description'),
                link: 'https://github.com/LuiRui07/iVision'
              }
            ].map((project, index) => (
              <a 
                key={index}
                href={project.link}
                target="_blank"
                rel="noreferrer"
                className="block group"
              >
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-all">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                      {project.icon}
                    </div>
                    <h3 className="font-medium text-gray-800 group-hover:text-blue-600 transition-colors">
                      {project.title}
                    </h3>
                  </div>
                  <p className="text-gray-600">{project.description}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      <footer className="bg-gray-800 text-white py-8 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mb-2">
            {t('contact')}{' '}
            <a href="mailto:luisruiznunez@gmail.com" className="text-blue-400 hover:underline">
              {t('email')}
            </a>
          </p>
          <p className="text-gray-400">{t('copyright')}</p>
        </div>
      </footer>
    </div>
  );
};

export default App;